
import HomePage from '../pages/home.vue';
import AboutPage from '../pages/about.vue';
import FormPage from '../pages/form.vue';
import UnauthorizedPage from '../pages/unauthorized.vue';


// employees
import EmployeeportalPage from '../pages/employee/employeeportal.vue';
import LoginPage from '../pages/employee/login.vue';
import ResetPage from '../pages/employee/reset.vue';
import UpdatepwdPage from '../pages/employee/updatepwd.vue';
import ProfilePage from '../pages/employee/profile.vue';


//Vacations
import VacationsPage from '../pages/employee/vacations/vacations.vue';
import EditVacationsPage from '../pages/employee/vacations/edit.vue';
import AddVacationsPage from '../pages/employee/vacations/add.vue';
import CalendarPage from '../pages/employee/vacations/calendar.vue';


import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';
import SettingsPage from '../pages/settings.vue';
import localforage from 'localforage';





//Tasks
import MytasksPage from '../pages/tasks/mytasks.vue';
import TaskPage from '../pages/tasks/task.vue';
import AddtaskPage from '../pages/tasks/addtask.vue';

//Files
import UploadFilesPage from '../pages/files/uploadfiles.vue';
import UploadImagesPage from '../pages/files/uploadimages.vue';

async function getToken() {
  const credStore = localforage.createInstance({
    storeName: "cred",
  });
  credStore.getItem('token').then(function(value){
    return value;
  })
}


async function checkAuth(to, from, resolve, reject) {
  var token1 ='';
  var router = this;
  const userStore = localforage.createInstance({
    storeName: "user",
  });
  const credStore = localforage.createInstance({
    storeName: "cred",
  });
  console.log('before')

await credStore.getItem('token').then(value => (token1 = value));
console.log('Test' + token1)
userStore.getItem('id')
.then(function(value){
  const url = apiUrl + "api/users/inactive";
  const data = {
    id:value
  }
  axios
  .post(url, data,  {
    headers: {
       "Accept" : "application/json",
      "Content-Type" : "application/json",
    }
   
  }).then(function(response){
    console.log(response)
    
    if(response.data == true && token1 != null){
      reject();
      console.log('rejected');
  
      
      console.log('Token Removed');
      credStore.setItem('token',null);

      var link = '/unauthorized/';
      router.navigate(link);
    }
  })
  .catch(function (error) {
    console.log(error);
  });


    }).catch(function (err) {
        // This code runs if there were any errors
        console.log(err);

      });



// var credStore = localforage.createInstance({
//   storeName: "cred",
// });
  
  credStore.getItem('token').then(
    function(value){
      var token = value;
      console.log('Value:'+ value);
  console.log('Token:' + token);

  if (token != null) {
    console.log('resolved');
    resolve();

  } else {
    var link = '/login/';
        var toUrl = to.url;
     console.log('To:' + toUrl);
        var ckUrl = toUrl.split("/");
    console.log(ckUrl[2]);
        if(ckUrl[2] === 'updatepwd'){//Allows for password update page
          var ul = toUrl.split("#");
          link  = ul[1] ;

        }
    reject();
    console.log('rejected');

    router.navigate(link);
    
  }
}
);
}


var routes = [
  {
    path: '/',
    component: HomePage,
    beforeEnter:checkAuth
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/unauthorized/',
    component: UnauthorizedPage,
  },
  //employees
  {
    path: '/employeeportal/',
    component: EmployeeportalPage,
    beforeEnter:checkAuth
  },
  {
    path: '/vacations/',
    component: VacationsPage,
  },
  {
    path: '/calendar/',
    component: CalendarPage,
  },
  {
    path: '/addvacations/',
    component: AddVacationsPage,
  },
  {
    path: '/editvacation/eventid/:eventId',
    component: EditVacationsPage,
  },

  

 
  
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/reset/',
    component: ResetPage,
  },
  {
    path: '/updatepwd/id/:id/passkey/:passkey',
    component: UpdatepwdPage,
  },
  {
    path: '/profile/',
    component: ProfilePage,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },

  //intraconnect
  //bigcommerce
  

//Tasks
{
  path: '/mytasks/:uid',
  component: MytasksPage,
},
{
  path: '/task/:id',
  component: TaskPage,
},
{
  path: '/addtask/',
  component: AddtaskPage,
},

//Files
{
  path: '/uploadfiles/:id',
  component: UploadFilesPage,
},

{
  path: '/uploadimages/:id',
  component: UploadImagesPage,
},


//Data 
  
  


  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
