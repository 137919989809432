<template>
  <f7-app>
    <f7-page name="add" login-screen>
      <f7-navbar title="Add Vacation" back-link="Back"></f7-navbar>

       <f7-block-title class="text-align-center"><h2>Add Vacation</h2></f7-block-title>
      <f7-list no-hairlines-md form>
        <f7-list-input
          label="Title"
          type="text"
          name="summary"
          id="summary"
          placeholder="Your name"
          :value="vacation.summary"
          @input="vacation.summary = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Start Date"
          type="date"
          :value="vacation.start.startDate"
          @input="vacation.start.startDate = $event.target.value,compareDates('start',$event)"
        ></f7-list-input>

       

        <f7-list-input
          label="Start Time"
          type="time"
          placeholder="Start Time"
          name="startTime"
          :value="vacation.start.startTime"
          @input="vacation.start.startTime = $event.target.value, setDataTime()"
          v-if= "addTimes === true"
        ></f7-list-input>

        <f7-list-input
          label="End Date"
          type="date"
          placeholder="End date"
          :value="vacation.end.endDate"
          @input="vacation.end.endDate = $event.target.value,compareDates('end',$event)"
        ></f7-list-input>

      

        <f7-list-input
          label="End Time"
          type="time"
          placeholder="End Time"
          :value="vacation.end.endTime"
          @input="vacation.end.endTime = $event.target.value, setDataTime()"
          v-if= "addTimes === true"
        ></f7-list-input>
          <f7-list-item
        checkbox
        name="addTimes"
        value="addTimes"
        title="Add Times"
        @change="addTime"
        :checked = "addTimes == true"
      ></f7-list-item>

        <f7-range slot="input" :value="50" :min="0" :max="100" :step="1"></f7-range>

        <f7-list-input
          type="textarea"
          label="Notes"
          placeholder="Add Notes"
          :value="vacation.description"
          @input="vacation.description=$event.target.value"
        >{{vacation.description}}</f7-list-input>
      </f7-list>
      <f7-list>
        <f7-row tag="p">
          <f7-button
            class="col"
            :class="{disabled: btnDisabled}"
            fill
            large
            raised
            @click.prevent="submitRequest"
            color="green"
          >Submit Vacation</f7-button>
        </f7-row>

        <f7-row tag="p">
          <f7-button class="col" fill large raised color="red" @click="$f7router.back()">Cancel</f7-button>
        </f7-row>
      </f7-list>
    </f7-page>
  </f7-app>
</template>
<script>
export default {
  data: function () {
    return {
      btnDisabled:false,
      token:"",
      addTimes:false,
      today: "", 
      type: "text",
      first_name: "",
      last_name: "",
       tz: "-05:00",
      vacation: {
        user_id:"",
        shop: "",
        email: "",
        summary: "",
        start: {
          date: null,
          startDate: "",
          startTime: "00:00:00",
          dateTime: null,
        },
        end: {
          date: null,
          endDate: "",
          endTime: "23:59:59",
          dateTime: null,
        },
        description: "",
      },
    };
  },
  methods: {
    getDateStr() {
      let s = new Date();
      //     let d = this.$moment([2020,10,6]).isDST();
      //  alert(d)

      return this.$moment(s).format("YYYY-MM-DD");
    },

    addDays(d) {
      let s = new Date();

      s = this.$moment(s, "YYYY-MM-DD").add(d, "days");
      return this.$moment(s).format("YYYY-MM-DD");
    },

    compareDates(def, event) {
      let today = this.getDateStr();

      let start = this.vacation.start.startDate;
      let end = this.vacation.end.endDate;
      this.vacation.end.date = end;
      this.vacation.start.date = start;

      if (start < today) {
        this.vacation.start.startDate = today;
        start = today;
      }

      if (start > end) {
        if (def == "start") {
           this.vacation.end.date = start;
           this.vacation.end.endDate = start;
        } else {
          this.vacation.start.date = end;
          this.vacation.start.startDate = end;
        }
      }

      if (this.vacation.start.startTime == "") {
        this.vacation.start.startTime = "00:00:00";
      }
      if (this.vacation.end.endTime == "") {
        this.vacation.end.endTime = "23:59:59";
      }
    if(this.addTimes === true){
      this.vacation.start.dateTime =
        this.vacation.start.startDate +
        "T" +
        this.vacation.start.startTime +
      this.setDST(this.vacation.start.startDate);
      this.vacation.end.dateTime =
        this.vacation.end.endDate + "T" + this.vacation.end.endTime + this.setDST(this.vacation.end.endDate);
      console.log(this.vacation);

      }else{
        this.vacation.end.date = this.$moment(this.vacation.end.endDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
        this.vacation.start.dateTime = null;
        this.vacation.end.dateTime = null;
        console.log(this.vacation);
      }

    },

    submitRequest() {
      const self = this;
      this.btnDisabled = true;
      const app = self.$f7;
      const router = self.$f7router;

      const addUrl = apiUrl + "api/vacations/add";
      const data = this.vacation;
      axios
        .post(addUrl, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" : "Bearer " + this.token,
          },
        })
        .then((response) => console.log(response))
        .then(function () {
          router.back({
            force: true,
            ignoreCache: true
          });
     
        })
        .catch(function (error) {
          console.log(error);
        });
    },
 

   addTime(){


      if(this.addTimes === true){
           
            this.addTimes = false;
            this.vacation.end.dateTime = null;
            this.vacation.start.dateTime = null;
            this.vacation.end.date = this.vacation.end.endDate;
            this.vacation.start.date = this.vacation.start.startDate; 

          }else{
        
            this.addTimes = true;
            this.vacation.end.date = null;
            this.vacation.start.date = null;
            this.vacation.start.startTime = "00:00:00";
            this.vacation.end.endTime = "23:59:00"; 
            this.vacation.end.dateTime = this.vacation.end.endDate + "T" + this.vacation.end.endTime + this.setDST(this.vacation.end.endDate);
            this.vacation.start.dateTime = this.vacation.start.startDate + "T" + this.vacation.start.startTime + this.setDST(this.vacation.start.startDate);
          }
          console.log(this.vacation)
         
          
    },

    setDataTime(){


          
          this.vacation.end.dateTime = this.vacation.end.endDate + "T" + this.$moment(this.vacation.start.startDate + " " + this.vacation.end.endTime).format('HH:mm:ss') + this.setDST(this.vacation.end.endDate);
          this.vacation.start.dateTime = this.vacation.start.startDate + "T" + this.$moment(this.vacation.start.startDate + " " + this.vacation.start.startTime).format('HH:mm:ss') + this.setDST(this.vacation.start.startDate);
    },

    setDST(date){
   
      var dst = this.$moment(date).isDST();
      if(dst == true){
        return "-04:00";
      }else{
        return "-05:00";
      }

    }

     },

  async created() {
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shop")
      .then((value) => (this.vacation.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("email")
      .then((value) => (this.vacation.email = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("id")
      .then((value) => (this.vacation.user_id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

 const credStore = this.$vlf.createInstance({
      storeName: "cred",
    });
      await credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    this.vacation.start.startDate = this.getDateStr();
    this.vacation.end.endDate = this.addDays();
    this.vacation.start.date =
      this.getDateStr() ;
    this.vacation.end.date =
      this.addDays();

    this.vacation.summary =
      this.first_name + " " + this.last_name + " - (Vacation Request)";
       this.$forceUpdate();
    console.log(this.vacation);
  },
};
</script>