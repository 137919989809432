<template>
<!-- <input type="file" accept="image/*;capture=camera"> -->
<div class="chooseFile">
<f7-block strong>
     <input name="doc" style="display:none" type="file" @change="loadFile" accept=".pdf,.txt" ref="fileInput"/>
     <f7-button fill color="blue" @click="$refs.fileInput.click()">Select File</f7-button>
</f7-block>
</div>
</template>
<script>
export default{
 props:{
   task:Object,
   uid:Number,
   },
data() {
    return {
        selectedFile: null,
        preview: null,
        tname: 'test1',
     };
    },

methods: {
    loadFile(event){
        console.log(event)
        
        // console.log(this.task)
        this.selectedFile = event.target.files[0]
        // this.preview = URL.createObjectURL(event.target.files[0])
       var name =  prompt('Name File')
       this.tname = name;
         this.$f7.popup.close('#fileloader');
 this.onupload();
    },
    onupload(){
            
        var id = this.task.id;

        const url = "https://app.truckserviceco.com/api/tasks/upload";
        const fd = new FormData();
        fd.append('doc',this.selectedFile,this.tname);
        fd.append('tname', this.tname);
        fd.append('id', id);
        fd.append('uid', this.uid);
console.log(fd)
           
        axios.post(url,fd).then(response => {
            this.$emit('picList', response.data.pics);
            this.$emit('docList', response.data.docs);
            console.log(response)
        });
         
     
    
    }
}
    }

</script>