<template>
<!-- <input type="file" accept="image/*;capture=camera"> -->
<div class="choose_file">
 <f7-block strong>
     <input name="img" style="display:none" type="file" @change="loadImage" accept="image/*" ref="imgInput"/>
     <f7-button fill color="blue" @click="$refs.imgInput.click()">Select Image or Take Picture</f7-button>
</f7-block>
</div>
</template>
<script>
export default{
 props:{
   task:Object,
   uid:Number,
   },
data() {
    return {
        selectedFile: null,
        preview: null,
        tname: '',
     };
    },

methods: {
    loadImage(event){
        console.log(event)
        
        // console.log(this.task)
        this.selectedFile = event.target.files[0]
        // this.preview = URL.createObjectURL(event.target.files[0])
       var name =  prompt('Name Image')
       this.tname = name;
       this.$f7.popup.close('#camera');
 this.onupload();
    },
    onupload(){
     
        //  this.$f7.popup.close('#camera');
        var id = this.task.id;

        const url = "https://app.truckserviceco.com/api/tasks/upload";
        const fd = new FormData();
        fd.append('doc',this.selectedFile,this.tname);
        fd.append('tname', this.tname);
        fd.append('id', id);
        fd.append('uid', this.uid);

           
        axios.post(url,fd).then(response => {
            this.$emit('picList', response.data.pics);
            this.$emit('docList', response.data.docs);
            console.log(response)
        });
         
     
    
    }
}
    }

</script>