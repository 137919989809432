<template>
    <div>
            <f7-block
                v-for="note in notel"
                :key="note.id"
            >
            <div class="note" :style="note.user_id != userid ? 'background:lightblue' : ''">
              <p
              :id="'note' + note.id" 
              @keydown="updateNote($event,note.id)" 
              >
               {{note.message}}  
              </p>
              <div class="note-footer">
               {{note.user.full_name}} {{formatTime(note.created)}}
               <span v-if="note.user_id == userid"><a @click="editNote(note.id)">Edit</a> <a @click="deleteNote(note.id)">Delete</a></span>
              </div>
              </div>
            </f7-block>

            <f7-list-input
              ref="add_note"
              label="Add Note"
              @keypress.native="saveNote($event)"
              type="textarea"
              placeholder="Type note..."
            >
             
            </f7-list-input>
</div>
</template>

<script>
export default {

 props:{
   notes: Array,
   userid: Number,
   taskid: Number
   },
 
  data(){ 
   return {
      notel:this.notes,
      text: ''



   }
   },
   mounted(){
    //    this.method()
       console.log(this.notes)
   },

   methods:{
       formatTime(time){
      var time = new Date(time);

      return this.$moment(time).format("YYYY-MM-DD h:mm A");
    

    },
   setBadge(num){
      if(num > 0 ){
        return num;
      }else{
        return '';
      }

    },
  
     removeSpace(val){

    val = val.toLowerCase();
    val = val.replaceAll(' ','_');

    return val;
  },
    editNote(id){
      var par = this.$el.querySelector('#note' + id)
      par.setAttribute('contenteditable', 'true');
      par.focus();
    //   par.addEventListener("v-bind:keypress",updateNote($event))
      console.log(par)
    },
    saveNote(event){

        if(event.keyCode == 13 ){
        event.preventDefault();
        console.log(event.target.value)
        this.addNote(event.target.value)
            event.target.value = '';
        }
       
    },
    updateNote(event,id){
        if(event.keyCode == 13 ){
        event.preventDefault();
        console.log('id:'+ id)
        console.log(event.target.innerText)
         event.target.setAttribute('contenteditable', 'false');
        event.target.blur()
        this.updateNoteRecord(event.target.innerText,id)
        }
    },
    addNote(note) {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      const url =
        "https://app.truckserviceco.com/api/notes/add";
      const data = {
          model:'Tasks',
        entity_id:this.taskid,
        type:'note',
        user_id:this.userid,
        message:note,

      }
      console.log(data)
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        // .then((response) => console.log(response))
        .then((response) => {
           console.log(response.data)
           this.notel = response.data
            //  console.log(response.data.length)
          this.$emit('notesCount',response.data.length);
   
  
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    deleteNote(id) {
        confirm('Are you sure you want to delete this note?')
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      const url =
        "https://app.truckserviceco.com/api/notes/delete";
      const data = {
    
        id:id,
        user_id:this.userid,
 
      }
      console.log(data)
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
           console.log(response.data)
           this.notel = response.data
          //  console.log(response.data.length)
          this.$emit('notesCount',response.data.length);
        //   router.back({
        //     force: true,
        //     ignoreCache: true
        //   });
  
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  updateNoteRecord(message,id) {
      
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      const url =
        "https://app.truckserviceco.com/api/notes/edit";
      const data = {
    
        id:id,
        user_id:this.userid,
        message:message,
        model:'Tasks',
        entity_id:this.taskid,
        type:'note',
      }
  
      console.log(data)
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
           console.log(response.data)
        //    this.notel = response.data
        //   router.back({
        //     force: true,
        //     ignoreCache: true
        //   });
  
        })
        .catch(function (error) {
          console.log(error);
        });
    },



   },
   
}

</script>