<template>
<f7-list>
 <f7-list-item title="Department" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search'}">
      <select name="department" v-model = "department">

          <option   v-for="department in departments" :key="department.id" :value="department.id"  >{{department.value}}</option>
          
    
    
      </select>
    </f7-list-item>
</f7-list>
</template>

<script>
export default {
    data(){
        return {
            title: 'Department',
            departments:[
              
            ],
            department:'',
        };
        },
async created() {
    const data = {
      menu:'department'
    };
console.log(data)
    const url = "https://app.truckserviceco.com/api/dropdowns/dropdownlist";
    
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
      this.departments = response.data
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  
    },
     watch: {
        department : function(val) {

        this.$emit('departmentUpdated', val)
    }
     }
  

};

</script>
