<template>
<f7-list>
 <f7-list-item title="Shop" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search'}">
      <select name="branch" v-model = "branch" >

          <option   v-for="shop in shops" :key="shop.id" :value="shop.id"  >{{shop.value}}</option>
          
    
    
      </select>
    </f7-list-item>
</f7-list>
</template>

<script>
export default {
    data(){
        return {
            title: 'Shop',
            shops:[ ],
            branch:'',
        };
        },
        async created() {
    const data = {
      menu:'locations'
    };

    const url = "https://app.truckserviceco.com/api/dropdowns/dropdownlist";
    
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
      this.shops = response.data
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  
    },
    methods: {

        selected(id){
alert(id)
        }


    },
    watch: {
        branch : function(val) {

        this.$emit('branchUpdated', val)
    }
  },
  

};

</script>
