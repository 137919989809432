<template>
  <f7-page name="vacations">
    <f7-navbar title="Vacation Requests" back-link="Back"><f7-link icon-f7="plus" class="right" href="/addvacations/"></f7-link></f7-navbar>

    <f7-list form>
      <f7-list-item v-for="vaca in vacations" v-bind:key="vaca.id" :link="link_var(vaca.id)" >
        <f7-block>
          <f7-block-header medium>{{vaca.user.full_name}} {{approvableStatus(vaca.approved)}}</f7-block-header>
          <!-- <div class="text-align-center">{{vaca.summary}}</div> -->
          <f7-block-header class="text-align-center">Start: {{formatStartDateTime(vaca.start_date_time)}} </f7-block-header>
          <f7-block-header class="text-align-center">End: {{formatEndDateTime(vaca.end_date_time)}}</f7-block-header>
        </f7-block>
      </f7-list-item>
    </f7-list>

    <f7-list>
      <f7-block-footer v-if="noResults===true">
        <h3>You have no time off scheduled. Follow link below to add a new request</h3>
        <f7-list>
          <f7-list-item link="/addvacations/" title="Add Vacation"></f7-list-item>
        </f7-list>
      </f7-block-footer>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  data: function () {
    return {
      noResults: false,
      vacations: null,
      id: "",
      first_name: "",
      last_name: "",
      token: "",
      start: "",
      end: "2040-01-01",
    };
  },

  async created() {
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
     const credStore = this.$vlf.createInstance({
      storeName: "cred",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("id")
      .then((value) => (this.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

       await credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    this.start = this.getDateStr();
    const data = {
      name: this.first_name + " " + this.last_name,
      start: this.start,
      end: this.end,
      id: this.id,
      // token: this.token
    }
  
    console.log(data);

    const url = apiUrl + "api/vacations/uservacations";

    await axios
      .post(url, data,  {
        headers: {
           "Accept" : "application/json",
          "Content-Type" : "application/json",
          "Authorization" : "Bearer " + this.token,
        }
       
      })
      // .then((response) => console.log(response))
      .then((response) => (this.vacations = response.data))
      .catch(function (error) {
        console.log(error);
      });

    if (this.vacations.length == 0) {
      this.noResults = true;
    }
console.log(this.vacations)
    this.$f7ready((f7) => {});
  },

  methods: {
    link_var: function (str) {

      return "/editvacation/eventid/" + str 
    },

    getDateStr() {
      let s = new Date();

      return this.$moment(s).format("YYYY-MM-DD");
    },

    formatDate(dt) {
      let date = "";
      if (dt.dateTime !== null) {
        date = dt.dateTime;
      } else {
        date = dt.date;
      }

      return this.$moment(date).format("MM-DD-YYYY");
    },
    
    formatStartDateTime(start){
      start = start.split('T');
      var date = start[0];
      var time = start[1].split('-')[0];
      if(time == '00:00:00'){
        return this.$moment(date).format("MM-DD-YYYY");
      }else{
     return this.$moment(date).format("MM-DD-YYYY") + ' ' + this.$moment(date + ' ' + time).format("hh:mm A");
      }


    },
    formatEndDateTime(end){
      end = end.split('T');
      var date = end[0];
      var time = end[1].split('-')[0];
      if(time == '23:59:00' || time == '23:59:59' ){
        return this.$moment(date).format("MM-DD-YYYY");
      }else{
     return this.$moment(date).format("MM-DD-YYYY") + ' ' + this.$moment(date + ' ' + time).format("hh:mm A");
      }


    },
    formatDateEnd(dt) {
      let date = "";
      if (dt.dateTime !== null) {
        date = dt.dateTime;
      } else {
        date = this.$moment(dt.date, "YYYY-MM-DD").subtract(1, "days");
      }

      return this.$moment(date).format("MM-DD-YYYY");
    },

    formatTime(tm) {
      if (tm.dateTime !== null) {
        return this.$moment(tm.dateTime).format("hh:mm A");
      }
    },
    approvableStatus(approved){

      switch(approved){
        case null:
          return '(Pending Approvable)';
        break;
        case true:
          return '(Approved)';
          break;
          case false:
          return '(Declined)';
          break;

      }

    }
  },
};
</script>
