<style>
.view {
  border-color: transparent;
  background-color: initial;
  color: initial;
}
</style>

<template>
  <f7-app>
    <f7-page name="edit" login-screen>
      <f7-navbar title="Edit Vacation" back-link="Back"></f7-navbar>

      <f7-block-title class="text-align-center"><h2>Edit Vacation</h2></f7-block-title>
      <f7-list no-hairlines-md form>
        <f7-list-input
          label="Title"
          type="text"
          name="summary"
          id="summary"
          placeholder="Your name"
          :value="vacation.summary"
          @input="vacation.summary = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Start Date"
          type="date"
          placeholder="Start date"
          defaultValue="2014-04-30"
          :value="vacation.start.startDate"
          @input="vacation.start.startDate = $event.target.value, compareDates('start',$event)"
        ></f7-list-input>

        
        <f7-list-input
          label="Start Time"
          type="time"
          placeholder="Start Time"
          name="startTime"
          id="startTime"
          :value="vacation.start.startTime"
          @input="vacation.start.startTime = $event.target.value, setDataTime()"
         v-if= "addTimes === true"
        ></f7-list-input>



        <f7-list-input
          label="End Date"
          type="date"
          placeholder="End date"
          :value="vacation.end.endDate"
          @input="vacation.end.endDate = $event.target.value,compareDates('end',$event)"
        ></f7-list-input>

     

        <f7-list-input
          label="End Time"
          type="time"
          placeholder="End Time"
          :value="vacation.end.endTime"
          @input="vacation.end.endTime = $event.target.value, setDataTime()"
          v-if= "addTimes === true"
        ></f7-list-input>


        <f7-list-item
        checkbox
        name="addTimes"
        value="addTimes"
        title="Add Times"
        @change="addTime"
        :checked = "addTimes == true"
      ></f7-list-item>

        <f7-range slot="input" :value="50" :min="0" :max="100" :step="1"></f7-range>

        <f7-list-input
          type="textarea"
          label="Notes"
          placeholder="Add Notes"
          :value="vacation.description"
          @input="vacation.description=$event.target.value"
        >{{vacation.description}}</f7-list-input>

      </f7-list>

      <f7-list>
        
        <f7-row tag="p"  >
          <f7-button class="col" fill large raised color="green" @click="submitRequest">Update</f7-button>
        </f7-row>

        <f7-row tag="p">
          <f7-button class="col" fill large raised color="red" @click="deleteVacation">Delete</f7-button>
        </f7-row>
        <f7-row tag="p">
          <f7-button class="col" fill large raised color="red" @click="$f7router.back()">Cancel</f7-button>
        </f7-row>
      </f7-list>
    </f7-page>
  </f7-app>
</template>
<script>
export default {

  data: function () {
    return {
      token:"",
      isEditing: false,
      eventId: "",
      id:"",
      addTimes: false,
      shop: "cov",
      email: "",
      tz: "-05:00",
      vacation: {
        id:"",
        eventId: "",
        user_id:"",
        email: "",
        summary: "",
        start: {
          date:"",
          startDate: "",
          startTime: "",
          dateTime: "",
        },
        end: {
          date:"",
          endDate: "",
          endTime: "",
          dateTime: "",
        },
        description: "",
      },
    };
  }, 

  async mounted() {
    this.eventId = this.$f7route.params.eventId;
    this.id = this.$f7route.params.eventId;
    this.shop = this.$f7route.params.shop;
   const credStore = this.$vlf.createInstance({
      storeName: "cred",
    });
      await credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      const url = apiUrl + "api/vacations/edit";

      const data = {
        id: this.id,
      
      };

     await axios.post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" : "Bearer " + this.token,
          },
        })
        // .then((response) => (console.log(response)))
        // .then((response) => (this.vacation = response.data))
        .then((response) => {
          this.vacation.id = response.data.id;
          this.vacation.end.dateTime = response.data.end_date_time;
          this.vacation.start.dateTime = response.data.start_date_time;
          this.vacation.summary = response.data.user.full_name;
          this.vacation.description = response.data.details;
        })
        .catch(function (error) {
          console.log(error);
        });

        const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shop")
      .then((value) => (this.vacation.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("email")
      .then((value) => (this.vacation.email = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      await userStore
      .getItem("id")
      .then((value) => (this.vacation.user_id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
              this.vacation.eventId = this.eventId;

            if(this.vacation.start.dateTime !== null && this.vacation.end.dateTime!== null){
              this.vacation.start.startDate =  this.$moment(this.vacation.start.dateTime).format("YYYY-MM-DD"); 
              this.vacation.end.endDate =  this.$moment(this.vacation.end.dateTime).format("YYYY-MM-DD"); 
              this.vacation.start.startTime = this.$moment(this.vacation.start.dateTime).format("HH:mm:ss"); 
              this.vacation.end.endTime = this.$moment(this.vacation.end.dateTime).format("HH:mm:ss");

              this.addTimes = true;
             
            }else{
              this.vacation.start.startDate =  this.$moment(this.vacation.start.date).format("YYYY-MM-DD"); 
              this.vacation.start.date =  this.$moment(this.vacation.start.date).format("YYYY-MM-DD");
              // this.vacation.end.endDate =  this.$moment(this.vacation.end.date).format("YYYY-MM-DD"); 
              this.vacation.end.endDate = this.$moment(this.vacation.end.date, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD");

              this.vacation.end.date =  this.$moment(this.vacation.end.date).format("YYYY-MM-DD"); 
            }

        console.log(this.vacation);
        this.$forceUpdate();
       
        console.log(this.vacation);
  },

  methods: {
    deleteVacation() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

   const url = apiUrl + "api/vacations/delete";

      const data = {
        id: this.id,
        user_id: this.vacation.user_id
      };

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" : "Bearer " + this.token,
          },
        })
        .then((response) => console.log(response))
        .then(function () {
         router.back({
            force: true,
            ignoreCache: true
          });
        })
        .catch(function (error) {
          console.log(error);
        });
        // this.$forceUpdate();
    },


    submitRequest() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      const addUrl = apiUrl + "api/vacations/update";
      const data = this.vacation;

      console.log(data);

      axios.post(addUrl, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization" : "Bearer " + this.token,
          },
        })
        .then((response) => console.log(response))
        .then(function () {
         router.back({
            force: true,
            ignoreCache: true
          });
        })
        .catch(function (error) {
          console.log(error);
        });
        // this.$forceUpdate();
    },
    parseDate(datetime) {
      return this.$moment(datetime).format("YYYY-MM-DD");
    },

    parseTime(datetime) {
      var time = datetime.split("T");
      if (time.length > 1) {
        time = time[1].split("-");
        return time[0];
      } else {
        return datetime;
      }
    },
    getDateStr() {
      let s = new Date();
      //     let d = this.$moment([2020,10,6]).isDST();
      //  alert(d)

      return this.$moment(s).format("YYYY-MM-DD");
    },




    updateInput(obj, prop, event) {
      Vue.set(obj, prop, event.target.value);
    },

    save() {
      this.cachedUser = Object.assign({}, this.user);
      this.isEditing = false;
    },
    cancel() {
      this.user = Object.assign({}, this.cachedUser);
      this.isEditing = false;
    },
    update(event) {
      let value = event.target.value;
      value = value.replace(/a/gi, "");
      this.input = value;
      console.log("update " + value);
      this.$forceUpdate();
    },

 
  compareDates(def, event) {
      let today = this.getDateStr();

      let start = this.vacation.start.startDate;
      let end = this.vacation.end.endDate;
      this.vacation.end.date = end;
      this.vacation.start.date = start;

      if (start < today) {
        this.vacation.start.startDate = today;
        start = today;
      }

      if (start > end) {
        if (def == "start") {
           this.vacation.end.date = start;
           this.vacation.end.endDate = start;
        } else {
          this.vacation.start.date = end;
          this.vacation.start.startDate = end;
        }
      }

      if (this.vacation.start.startTime == "") {
        this.vacation.start.startTime = "00:00:00";
      }
      if (this.vacation.end.endTime == "") {
        this.vacation.end.endTime = "23:59:59";
      }
    if(this.addTimes === true){
      this.vacation.start.dateTime =
        this.vacation.start.startDate +
        "T" +
        this.vacation.start.startTime +
        this.setDST(this.vacation.start.startDate);
      this.vacation.end.dateTime =
        this.vacation.end.endDate + "T" + this.vacation.end.endTime + this.setDST(this.vacation.end.endDate);
      console.log(this.vacation);

      }else{
        this.vacation.end.date = this.$moment(this.vacation.end.endDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
        this.vacation.start.dateTime = null;
        this.vacation.end.dateTime = null;
        console.log(this.vacation);
      }

   

      console.log(this.vacation);
      this.$forceUpdate();
    },

   

    addTime(){


      if(this.addTimes === true){
           
            this.addTimes = false;
            this.vacation.end.dateTime = null;
            this.vacation.start.dateTime = null;
            this.vacation.end.date = this.vacation.end.endDate;
            this.vacation.start.date = this.vacation.start.startDate; 

          }else{
        
            this.addTimes = true;
            this.vacation.end.date = null;
            this.vacation.start.date = null;
            this.vacation.start.startTime = "00:00:00";
            this.vacation.end.endTime = "23:59:00"; 
            this.vacation.end.dateTime = this.vacation.end.endDate + "T" + this.vacation.end.endTime + this.setDST(this.vacation.end.endDate);
            this.vacation.start.dateTime = this.vacation.start.startDate + "T" + this.vacation.start.startTime + this.setDST(this.vacation.start.startDate);
            // this.compareDates();
          }
          console.log(this.vacation)
         
          
    },

     setDataTime(){
        
          this.vacation.end.dateTime = this.vacation.end.endDate + "T" + this.$moment(this.vacation.start.startDate + " " + this.vacation.end.endTime).format('HH:mm:ss') + this.setDST(this.vacation.end.endDate);
          this.vacation.start.dateTime = this.vacation.start.startDate + "T" + this.$moment(this.vacation.start.startDate + " " + this.vacation.start.startTime).format('HH:mm:ss') + this.setDST(this.vacation.start.startDate);
          console.log(this.vacation.start.dateTime);
          this.$forceUpdate();
    },

    setDST(date){
   
      var dst = this.$moment(date).isDST();
      if(dst == true){
        return "-04:00";
      }else{
        return "-05:00";
      }

    }

    
  },
};
</script>