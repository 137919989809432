<template>
<div id="this-task">
  <f7-page name="task">
    <f7-navbar title="Task" back-link="Back"></f7-navbar>
     <f7-row class="details">
 <f7-badge
 class="badge"
  :class="removeSpace(task._branch.value)" 
  color="green">{{task._branch.value}}</f7-badge>
  <f7-badge  
  class="badge"
  :class="removeSpace(task._department.value)" 
  color="blue">{{task._department.value}}</f7-badge>
  <f7-badge  
  class="badge" 
  :class="removeSpace(task._status.value)"
  color="blue">{{task._status.value}}</f7-badge>
  </f7-row>
    <f7-card
      outline
      :title="task.name"
      :content="task.description"
      :footer="'Due:' + formatTime(task.due)"
      @taphold.native = "testTap"
    >
    </f7-card>

    <div class="upper-area">
      <f7-list accordion-list inset>
        <f7-list-item
          id="note"
          accordion-item
          title="Notes"
          :badge="noteCount"
          badge-color="red"
        >
          <f7-accordion-content id="note">
            <Notes :notes="task.notes" :userid="user.id" :taskid="task.id" @notesCount="setNoteCount"/>
          
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item
          accordion-item
          title="Pictures"
          :badge="picCount"
          badge-color="red"
        >
          <f7-accordion-content>
            <f7-swiper
               v-if="pics.length > 0"
              pagination
              navigation
              scrollbar
              :loop="false"
              :speed="500"
              :slidesPerView="1"
              :spaceBetween="20"
            >
              <f7-swiper-slide 
                v-for="(pic, index) in pics"
                :key="index"
                :title="pic"
                 @taphold.native="deletePic(pic)"
                >
          
      <figure>
  <figcaption>{{formatTitle(pic)}}</figcaption>
          <img class="tapHold" :src="domain + fileUrl + pic"  @dblclick="deletePic(pic)">
        </figure>
              </f7-swiper-slide>
               <f7-swiper-slide
                ><div v-on:click="opencam">
                  <div>Add image...</div>
                  <i class="icon f7-icons color-blue slider_add">camera</i>
                </div></f7-swiper-slide
              >
              </f7-swiper>

             <f7-block
               v-else-if="pics.length == 0"
              class="upload_icon"
               >
            <div v-on:click="opencam">
                  <div>Add image...</div>
                  <i class="icon f7-icons color-blue slider_add">camera</i>
                </div>
            </f7-block>

          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item
          accordion-item
          title="Documents"
          :badge="setBadge(docCount)"
          badge-color="red"
        >
          <f7-accordion-content>
            <f7-swiper
              v-if="docs.length > 0"
              pagination
              navigation
              scrollbar
              :speed="500"
              :slidesPerView="3"
              :spaceBetween="20"
            >
              <f7-swiper-slide
               v-for="(doc, index) in docs"
                :key="index"
                @taphold.native="deleteDoc(doc)"
                >
                <div class="tapHold" style="padding:10px;" @dblclick="deleteDoc(doc)" >
                 
                <iframe
                  type="application/pdf"
                  :src="domain + fileUrl + doc + '#zoom=FitW'"
                 
                ></iframe>
              </div>
              </f7-swiper-slide>
             
              
              <f7-swiper-slide
                ><div v-on:click="upload_file">
                  <div>Add File...</div>
                  <i class="icon f7-icons color-blue slider_add"
                    >square_arrow_up</i
                  >
                </div></f7-swiper-slide
              >
            </f7-swiper>

            <f7-block
              v-else-if="docs.length == 0"
              
              >
             <div v-on:click="upload_file" class="upload_icon">
                  <div>Add File...</div>
                  <i class="icon f7-icons color-blue slider_add"
                    >square_arrow_up</i>
                  
                </div>
              
              </f7-block>

          </f7-accordion-content>
        </f7-list-item>
   
           

          <Buttons :statuses="statuses" :id="tid" :user="user" :status="task._status.value"/>
       
      </f7-list>
  <div style="height:40px;">


  </div>
  <f7-popup id="camera" class="cam-popup">
      <!-- <f7-popup id="camera" class="cam-popup" :opened="popupOpened" @popup:closed="popupOpened = false"> -->
<f7-page>
      <f7-navbar title="Upload Pictures">
        <f7-nav-right>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-right>
      </f7-navbar>

<Camera  :task="task" :uid="user.id" @picList="setPics" @docList="setDocs" />

      </f7-page>
        </f7-popup>
<f7-popup id="fileloader" class="file-popup" >
         <!-- <f7-popup id="fileloader" class="file-popup" :opened="popupOpened" @popup:closed="popupOpened = false"> -->
<f7-page>
      <f7-navbar title="Upload Files">
        <f7-nav-right>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <FileLoader  :task="task" :uid="user.id" @picList="setPics" @docList="setDocs" />

      </f7-page>
        </f7-popup>
    </div>

    <f7-toolbar bottom class="bottom-toolbar">
    <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>
      
      <f7-link v-on:click="upload_file"
        ><i class="icon f7-icons color-blue">square_arrow_up</i></f7-link
      >
      <f7-link v-on:click="toggleNote"
        ><i class="icon f7-icons color-blue">chat_bubble_text</i></f7-link
      >
    <f7-link popup-open=".cam-popup"
        ><i class="icon f7-icons color-blue">camera</i></f7-link
      >
    </f7-toolbar>
   
  </f7-page>
  </div>
</template>
    <script>
    import Buttons from '../../components/Buttons.vue'
    import Notes from '../../components/Notes.vue'
    import Camera from '../../components/CameraComp.vue'
    import FileLoader from '../../components/FileLoader.vue'
export default {
  components: {
    Buttons,
    Notes,
    Camera,
    FileLoader
  },

  data() {
    return {
      tid: "",
      pics:"",
      picCount:"",
      docs:"",
      docCount:"",
      task:[],
      notes:"",
      noteCount:"",
      user:{
        first_name:"",
        last_name:"",
        id:"",
        shop:"",
      },
      statuses:[],
      domain: "https://app.truckserviceco.com/",
      fileUrl: "webroot/files/Tasks/doc/",
    };
  },
   mounted() {

    this.id = this.$f7route.params.id;
    this.tid = this.$f7route.params.id;
    var user =  this.$vlf.createInstance({
      storeName: 'user'
  });

  user.getItem("first_name")
      .then((value) => (this.user.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      user.getItem("last_name")
      .then((value) => (this.user.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      user.getItem("id")
      .then((value) => (this.user.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      user.getItem("shop")
      .then((value) => (this.user.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      
  },
  async created() {
    const data = {
      tid: this.$f7route.params.id,
    };

    const url = "https://app.truckserviceco.com/api/tasks/task";
    // const url = "http://10.10.10.116/servco/api/tasks/task";


    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.task = response.data.task;
        this.docs = response.data.docs;
        this.docCount = this.setBadge(response.data.docs.length);
        this.pics = response.data.pics;
        this.picCount = this.setBadge(response.data.pics.length);
        this.notes = response.data.task.notes;
        this.noteCount = this.setBadge(response.data.task.notes.length);
        this.statuses = response.data.statuses;
        console.log(response);
      })
      // .then((response) => (this.vacations = response.data.items))
      .catch(function (error) {
        console.log(error);
      });
  },


  methods: {
    opencam() {
      const app = this.$f7;
      app.popup.open('#camera');
    },
    upload_file() {
     const app = this.$f7;
      app.popup.open('#fileloader');
    },

    toggleNote() {
      alert("add note");
    },

    formatTime(time){
      var time = new Date(time);

      return this.$moment(time).format("YYYY-MM-DD h:mm A");
    

    },
    setBadge(num){
      if(num > 0 ){
        return num;
      }else{
        return '';
      }

    },
     removeSpace(val){

    val = val.toLowerCase();
    val = val.replaceAll(' ','_');

    return val;
  },
  formatTitle(val){
    val = val.split('_');
    return val[2];
  },
    test(){ 
        alert('here');
        },
  deletePic(pic){
         var del =  confirm('Delete this image?')

      if(del == true){
      this.sendDeleteRequest(pic);
      }

        },
    deleteDoc(doc){
         var del =  confirm('Delete this document?')

      if(del == true){
      this.sendDeleteRequest(doc);
      }

        },
  sendDeleteRequest(filename){

 const data = {
      filename:filename,
    };
console.log(data)
    const url = "https://app.truckserviceco.com/api/tasks/deletedoc";



    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.pics = response.data.pics;
        this.picCount = this.setBadge(response.data.pics.length);
        this.docs = response.data.docs;
        this.docCount = this.setBadge(response.data.docs.length);
        // console.log(response)
      }).catch(function (error) {
        console.log(error);
      });

  },
  
  setPics(pics){
    this.pics = pics;
      this.picCount = this.setBadge(pics.length);
  },
  setDocs(docs){
    this.docs = docs;
      this.docCount = this.setBadge(docs.length);
  },
  setNoteCount(noteCount){
console.log(noteCount)
      this.noteCount = this.setBadge(noteCount);
  },
  testTap(){
    alert('yes')
  }
      
  },
};
</script>
<style>
figcaption{
 display: block;
  font-size: .8em;
  padding-top: .5em;
  position: absolute;
  bottom: 0;

  left: 25%;

}


</style>