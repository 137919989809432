<style>
.btnfont{
  font-size:.45em !important;
  display: flex;
  flex-wrap: wrap;
}
</style>
<template>

  <f7-block>
   
    <f7-row>
     
      <f7-col 
       v-for="btn in setBtns(status)"
        :key="searchObjArr(statuses,btn.name).id">
        <div><f7-button 
        v-on:click="updateStatus(searchObjArr(statuses,btn.name).id)"
        class="btnfont"
        :class="removeSpace(btn.name)"
        raised fill>{{btn.name}}</f7-button></div>

      </f7-col>
   
 
      <!-- </f7-col> -->
    </f7-row>
  </f7-block>
  </div>
</template>
<script>
export default {

 props:{
   'statuses': Array,
   'id': String,
   'user' : Object,
   'status' : String
   },
 
  data(){ 
   return {
     btns: []
    
  
   } 
    }, 
  
    methods:{ 

      removeSpace(val){

      val = val.toLowerCase();
      val = val.replaceAll(' ','_');

      return val;
      },

      setBtns(val){
        var btns = [];
        switch(val){
          case 'Working On Request':
            case 'Info Requested':
              
            btns = [
                  {
                'name':'On Hold',
                'disabled': ''
                  },
                  {
                'name':'Completed',
                'disabled': ''
                  },
                  {
                'name':'Info Requested',
                'disabled': ''
                  },
                ];

          break;
          case 'On Hold':
            case 'Assigned':
                btns = [
                  {
                'name':'Working On Request',
                'disabled': ''
                  },
                  {
                'name':'Completed',
                'disabled': ''
                  },
                  {
                'name':'Info Requested',
                'disabled': ''
                  },
                ];
          break;
          case 'Completed':
             btns = [
                  {
                'name':'Working On Request',
                'disabled': true
                  },
                  {
                'name':'Completed',
                 'disabled': true
                  },
                  {
                'name':'Info Requested',
                'disabled': true
                  },
                ];

          break;
        }
      return btns;

      },
    searchObjArr(arr,val){

    return arr.find(o => o.value === val);
    },
    updateStatus(stat) {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      const url =
        "https://app.truckserviceco.com/api/tasks/updatestatus";
      const data = {
        id:this.id,
        status:stat,
        userid:this.user.id,
      }
      // console.log(data)
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => console.log(response))
        .then(function () {
          router.back({
            force: true,
            ignoreCache: true
          });
     
        })
        .catch(function (error) {
          console.log(error);
        });
    },
     
        }
}
</script>