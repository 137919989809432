<template>
  <f7-app :params="f7params">
    <!-- Left panel with cover effect-->
    <f7-panel left cover theme-dark>
      <f7-view>
        <f7-page>
          <f7-navbar title="Left Panel"></f7-navbar>
          <f7-block>Left panel content goes here</f7-block>
        </f7-page>
      </f7-view>
    </f7-panel>

    <!-- Right panel with reveal effect-->
    <!-- <f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Right Panel"></f7-navbar>
        <f7-block>Right panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel> -->

    <!-- Your main view, should have "view-main" class -->
    <f7-view
      main
      :push-state="true"
      push-state-separator=""
      class="safe-areas"
      url="/"
    ></f7-view>

    <!-- Popup -->
    <f7-popup id="my-popup">
      <f7-view>
        <f7-page>
          <f7-navbar title="Popup">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <p>Popup content goes here.</p>
          </f7-block>
        </f7-page>
      </f7-view>
    </f7-popup>

    <f7-login-screen id="my-login-screen">
      <f7-view>
        <f7-page login-screen>
          <f7-login-screen-title>Login</f7-login-screen-title>
          <f7-list form>
            <f7-list-input
              type="text"
              name="username"
              placeholder="Your username"
              :value="username"
              @input="username = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              type="password"
              name="password"
              placeholder="Your password"
              :value="password"
              @input="password = $event.target.value"
            ></f7-list-input>
          </f7-list>
          <f7-list>
            <f7-list-button
              title="Sign In"
              @click="alertLoginData"
            ></f7-list-button>
            <f7-block-footer>
              Some text about login information.<br />Click "Sign In" to close
              Login Screen
            </f7-block-footer>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-login-screen>
  </f7-app>
</template>
<script>
import routes from "../js/routes.js";

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        name: "Metcon Mobile", // App name
        theme: "auto", // Automatic theme detection
        touch: {
          tapHold: true,
          tapHoldDelay: 1000,
        },

        // App routes
        routes: routes,
        // Register service worker
        serviceWorker: {
          path: "/service-worker.js",
        },
      },
      // Login screen data
      username: "",
      password: "",
      uid:"",
    };
  },
  methods: {
    alertLoginData() {
      this.$f7.dialog.alert(
        "Username: " + this.username + "<br>Password: " + this.password,
        () => {
          this.$f7.loginScreen.close();
        }
      );
    },
    forceSWupdate() {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister();
              alert("Updated. Close app and reopen for update to take affect.");
            }

          });

       
        const router = this.$f7router;
        router.navigate("/");
      }
    },
 

  },
  mounted() {
    this.$f7ready((f7) => {
      // Call F7 APIs here

      f7.on("serviceWorkerRegisterSuccess", (registration) => {
        console.log("serviceWorkerRegisterSuccess");
        const reg = registration;
        reg.onupdatefound = () => {
          console.log("serviceWorker onupdatefound");
          const installingWorker = reg.installing;
          installingWorker.onstatechange = () => {
            console.log("serviceWorker installingWorker onstatechange");
            if (installingWorker.state === "installed") {
              console.log("serviceWorker installed");
               if (navigator.serviceWorker.controller) {
                console.log('serviceWorker navigator.serviceWorker.controller');
                // We inform the user a new update is available
                this.forceSWupdate(() => {
                  
                });
              }
            }
          };
        };
      });
    });
  },
};
</script>
