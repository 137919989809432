<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar large :sliding="false">
      <f7-nav-left>
        <!-- <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link> -->
      </f7-nav-left>
      <f7-nav-title sliding>Metcon Mobile</f7-nav-title>
      <f7-nav-right>
       <f7-link no-link-class href="/profile/"><span>{{first_name}} {{last_name}}</span></f7-link>
      </f7-nav-right>
      <f7-nav-title-large>Metcon Mobile </f7-nav-title-large>

    </f7-navbar>
    <!-- Toolbar-->
    <f7-toolbar bottom>
      <f7-link>Left Link</f7-link>
      <f7-link>Right Link</f7-link>
    </f7-toolbar>

    <!-- Page content-->

    <f7-block-title>Navigation</f7-block-title>
    <f7-list>
      <!-- <f7-list-item link="/about/" title="About"></f7-list-item> -->
      <f7-list-item link="/employeeportal/" title="Employee Portal"></f7-list-item>
      <!-- <f7-list-item :link="'/mytasks/' + uid" title="Tasks"></f7-list-item>
      <f7-list-item link="/links/" title="Links"></f7-list-item> -->
      <!-- <f7-list-item link="/login/" title="Login"></f7-list-item> -->
      <f7-list-item link="/settings/" title="Settings"></f7-list-item>
        <!-- <f7-list-item link="/parts/" title="Daily Parts"></f7-list-item> -->
        
        <!-- <f7-list-item link="/task/3" title="Task Test"></f7-list-item>
        <f7-list-item link="/uploadfiles/3" title="Upload Files"></f7-list-item>
        <f7-list-item link="/uploadimages/3" title="Upload Images"></f7-list-item> -->
    </f7-list>






  </f7-page>
</template>

<script>
export default {
  data: function () {
    return {
      first_name:"",
      last_name:"",
      uid:"",

    }
    },

   mounted() {

   var user =  this.$vlf.createInstance({
      storeName: 'user'
  });

  user.getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
  user.getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    user.getItem("id")
      .then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });



   },
   methods:{


   }



 };
</script>
